import React from 'react';

function DostiSix() {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src='https://drive.google.com/file/d/17EfXkB5NFnRldAvxI91wBilFpgwZNIfe/preview'
        style={{ height: '100%', width: '100%', border: 'none' }}
        title='PDF Viewer'
      />
    </div>
  );
}

export default DostiSix;
